/* Default styles */
.element {
  width: 50%;
  font-size: 1rem;
}

/* Tablet-sized screens (768px - 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
  .element {
    width: 75%;
    font-size: 0.9rem;
  }
}

/* Mobile-sized screens (up to 767px) */
@media (max-width: 767px) {
  .element {
    width: 100%;
    font-size: 0.8rem;
  }
}


body {
  background-color:#006C8570;
}

.page-container {
  text-align: center; /* Center text within the container */
}

.result-table {
  margin: 0 auto;
  width: 100%;
  border-collapse: collapse;
}

.result-table th,
.result-table td {
  margin: 0;
  padding: 0.5rem;
  /* border: 2px solid grey; */
  background-color: rgb(240, 236, 236);
  box-sizing: border-box;
  font-size: 1.5rem;
  white-space: nowrap; /* Prevent text from wrapping */
}

.table-body {
  margin: 0 auto;
  width: 100%; /* Let the table adjust to the content */
  border-collapse: collapse; /* Add this to remove gaps between cells */
}

.table-body th,
.table-body td {
  margin: 0;
  padding: 0.5rem;
  background-color: rgb(240, 236, 236);
  box-sizing: border-box;
  font-size: 1.25rem;
  white-space: nowrap; /* Prevent text from wrapping */
}
.table-body th {
  border: 2px solid grey;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.table-body td {
  padding-left: 0.75rem;
  border-left: 2px solid grey;
  border-right: 2px solid grey;
  box-sizing: border-box;
  text-align: left;
  background-color: rgb(240, 236, 236);
}

.table-body tbody tr:nth-child(even) {
  background-color: rgb(196, 193, 193);
}
.table-body tbody tr:nth-child(odd) {
  background-color: rgb(240, 236, 236);
}

.table-body tr:last-child {
  border-bottom: 2px solid grey;
}

.custom-select {
  height: 100%;
  width: 100%;
  border: none;
  background-color: transparent;
  font-size: 1.3rem;
}

.footer {
  margin-left: 3rem;
}

.header {
  display: flex;
  align-items: center; 
  justify-content: left; 
  width: 100%;
}

.header h1 {
  font-size: 3rem;
  margin: 0;
  width: 70%;
}

.header img {
  margin-right: 0rem; 
  margin-left: 5rem;
  margin-bottom: 0rem;
  margin-top: 2rem;
  width: 10%;
}
.info-container {
  position: relative;
  display: inline-block;
}

.info-icon {
  margin-left: 5px;
  cursor: pointer;
}

.info-text {
  display: none;
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  font-size: 14px;
  white-space: nowrap;
  z-index: 1;
  text-align: left;
}
.info-text.right {
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.info-text.left {
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.info-container:hover .info-text {
  display: block;
}

.table-container {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Align the button and table */
  align-items: flex-start; /* Align the button to the top */
  margin: 0 auto;
}

.table-button {
  background-color: #006C85; 
  color: #fff; 
  border: none;
  padding: 10px 20px; 
  font-size: 1rem; 
  cursor: pointer;
  align-self: flex-end;
  margin-bottom: 5px;
}

.table-button:hover {
  background-color: #00556B; 
}

.help-button {
  background-color: #006C85; 
  color: #fff; 
  border: none;
  padding: 10px 20px; 
  font-size: 1rem; 
  cursor: pointer;
  align-self: flex-start;
  margin-bottom: 5px;
}

.help-button:hover {
  background-color: #00556B; 
}

.table-buttons-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}